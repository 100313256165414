<div class="container text-center pt-5">
    <img class="logo"
         src="/assets/images/logo/agrojabes-logo.svg"
         alt="AgroJabes Logo" title="AgroJabes Logo">
    <div class="py-3">
        <h2>coming soon...</h2>
    </div>
    <a type="button" class="btn btn-success" href="mailto:mail@agrojabes.com" aria-label="Send email button icon">
        <i class="fa-solid fa-envelope mx-1"></i> mail
    </a>
    <div class="pt-5">
        <app-company-link></app-company-link>
        <p class="text-muted">All rights reserved</p>
    </div>
</div>
