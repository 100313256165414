import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SecureComponent} from './secure.component';
import {RouterModule, RouterOutlet, Routes} from "@angular/router";

// user secure pages
import {PanelComponent} from './panel/panel.component';

const routes: Routes = [

    // user secure pages
    {path: '', redirectTo: 'panel', pathMatch: 'full'},
    {path: 'panel', component: PanelComponent, title: 'User Panel'}

];

@NgModule({
    declarations: [
        SecureComponent,
        PanelComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        RouterOutlet
    ]
})
export class SecureModule {
}
