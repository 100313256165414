import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AdminComponent} from './admin.component';
import {RouterModule, RouterOutlet, Routes} from "@angular/router";

// admin pages
import {DashboardComponent} from './dashboard/dashboard.component';

const routes: Routes = [

    // admin pages
    {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
    {path: 'dashboard', component: DashboardComponent, title: 'Dashboard'}

];

@NgModule({
    declarations: [
        AdminComponent,
        DashboardComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        RouterOutlet
    ]
})
export class AdminModule {
}
