import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PublicComponent} from './public.component';
import {RouterModule, RouterOutlet, Routes} from "@angular/router";
import {SharedModule} from "../shared/shared.module";

// public pages
import {HomeComponent} from './home/home.component';

// not found
import {NotFoundComponent} from './not-found/not-found.component';


const routes: Routes = [

    // public pages
    {path: '', component: HomeComponent},

    // not found
    {path: '**', component: NotFoundComponent}

];

@NgModule({
    declarations: [
        PublicComponent,
        HomeComponent,
        NotFoundComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        RouterOutlet,
        SharedModule
    ]
})
export class PublicModule {
}
