import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthComponent} from './auth.component';
import {RouterModule, RouterOutlet, Routes} from "@angular/router";

// auth pages
import {LoginComponent} from './login/login.component';
import {PasswordComponent} from './password/password.component';
import {PasswordResetComponent} from './password-reset/password-reset.component';
import {RegisterComponent} from './register/register.component';

const routes: Routes = [

    // auth pages
    {path: '', redirectTo: 'login', pathMatch: 'full'},
    {path: 'login', component: LoginComponent, title: 'AgroJabes - Login'},
    {path: 'password', component: PasswordComponent, title: 'AgroJabes - Password'},
    {path: 'password-reset', component: PasswordResetComponent, title: 'AgroJabes - Password Reset'},
    {path: 'register', component: RegisterComponent, title: 'AgroJabes - Register'}

];

@NgModule({
    declarations: [
        AuthComponent,
        LoginComponent,
        PasswordComponent,
        PasswordResetComponent,
        RegisterComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        RouterOutlet
    ]
})
export class AuthModule {
}
